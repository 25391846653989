import { createStore } from "vuex";

import apiModule from "./modules/apis.js";
import authModule from "./modules/auth/index.js";

const store = createStore({
  modules: {
    apis: apiModule,
    auth: authModule,
  },
});

export default store;
