export default {
  namespaced: true,
  state() {
    return {
      apiKey: null,
      apis: [
        {
          id: "1",
          title: "Get AQB facility location information",
          description: "AQB facility location and detail information",
          category: "air",
          baseUrl: "https://prxy.env.nm.gov",
          endPoint: "/get-facility-location-info/",
          parameters: [
            { id: 1, value: "first_row", label: "First Row", inputValue: 0 },
            {
              id: 2,
              value: "number_of_rows",
              label: "Number of Rows",
              inputValue: 10,
            },
          ],
          responseType: "0",
        },
        {
          id: "2",
          title: "Get AQB NSR Issued Data",
          description: "Get NSR Issued data for AQB facilities",
          category: "air",
          baseUrl: "https://prxy.env.nm.gov",
          endPoint: "/get-nsr-issued/",
          parameters: [
            { id: 1, value: "first_row", label: "First Row", inputValue: 0 },
            {
              id: 2,
              value: "number_of_rows",
              label: "Number of Rows",
              inputValue: 10,
            },
          ],
          responseType: "1",
        },
        {
          id: "3",
          title: "Get Suspected Release Cause List",
          description: "Get Suspected Release Cause List",
          category: "air",
          baseUrl: "https://prxy.env.nm.gov",
          endPoint: "/get-suspected_release_cause_list/",
          parameters: [{ id: 1, value: "id", label: "ID", inputValue: null }],
          responseType: "0",
        },
        {
          id: "4",
          title: "Default City State by ZIP",
          description: "Look up default city and state for a ZIP code.",
          category: "service",
          baseUrl: "https://prxy.env.nm.gov",
          endPoint: "/address-citystate/",
          parameters: [
            {
              id: 1,
              value: "zip",
              label: "A five digit ZIP, including leading zeroes if necessary.",
              inputValue: "87544",
            },
          ],
          responseType: "2",
        },
        {
          id: "5",
          title: "Get PSTB Storage Tank Count Information",
          description:
            "Get status counts of PSTB storage tanks given a variety of criteria.",
          category: "waste",
          baseUrl: "https://prxy.env.nm.gov",
          endPoint: "/get-storage-tank-count/",
          parameters: [
            {
              id: 1,
              value: "owner_id",
              label: "PSTB Owner ID",
              inputValue: "",
            },
            { id: 2, value: "owner_name", label: "Owner Name", inputValue: "" },
            {
              id: 3,
              value: "facility_id",
              label: "PSTB Facility ID",
              inputValue: "",
            },
            {
              id: 4,
              value: "facility_name",
              label: "Facility Name",
              inputValue: "",
            },
            {
              id: 5,
              value: "facility_address",
              label: "Facility Address",
              inputValue: "",
            },
            {
              id: 6,
              value: "facility_city",
              label: "Facility City",
              inputValue: "",
            },
            {
              id: 7,
              value: "facility_zip",
              label: "Facility ZIP Code",
              inputValue: "",
            },
            {
              id: 8,
              value: "facility_county",
              label: "Facility County",
              inputValue: "",
            },
            {
              id: 9,
              value: "active_only_flag",
              label: "Show only active facility information (Y/N)",
              inputValue: "N",
            },
            { id: 10, value: "first_row", label: "First Row", inputValue: 0 },
            {
              id: 11,
              value: "number_of_rows",
              label: "Number of Rows",
              inputValue: 10,
            },
          ],
          responseType: "1",
        },
        {
          id: "6a",
          title: "Assessed Units - Geometry Attributes (Lakes)",
          description:
            "Returns attributes of the assessed units geometry. For lakes it returns the area, perimeter length, and elevation of the lake.",
          category: "water",
          baseUrl: "https://prxy.env.nm.gov",
          endPoint: "/au-stats/lakes",
          parameters: [
            {
              id: 1,
              value: "AU-ID",
              label: "Assessed Unit Id.",
              inputValue: "",
            },
          ],
          responseType: "0",
        },
        {
          id: "6b",
          title: "Assessed Units - Geometry Attributes (Streams)",
          description:
            "Returns attributes of the assessed units geometry. For streams it return the length, slope, percent slope, starting point elevation and ending point elevation.",
          category: "water",
          baseUrl: "https://prxy.env.nm.gov",
          endPoint: "/au-stats/streams",
          parameters: [
            {
              id: 1,
              value: "AU-ID",
              label: "Assessed Unit Id.",
              inputValue: "",
            },
          ],
          responseType: "0",
        },
        {
          id: "7",
          title: "Public Water System Drinking Water Sources",
          description:
            "This API returns current public water system drinking water sources (wells).",
          category: "water",
          baseUrl: "https://prxy.env.nm.gov",
          endPoint: "/dw-sources/",
          parameters: [],
          responseType: "4",
        },
        {
          id: "8",
          title: "Public Water System Drinking Water Violation",
          description:
            "This API returns details of a specific violation similar to Drinking Water Watch for a given violation ID.",
          category: "water",
          baseUrl: "https://prxy.env.nm.gov",
          endPoint: "/dw-violation/",
          parameters: [
            {
              id: 1,
              value: "violation_id",
              label: "Violation ID in SDWIS",
              inputValue: "1254",
            },
          ],
          responseType: "3",
        },
        {
          id: "9",
          title: "Drinking Water Violations",
          description:
            "This API returns violations similar to Drinking Water Watch for a given public water system ID.",
          category: "water",
          baseUrl: "https://prxy.env.nm.gov",
          endPoint: "/dw-violations/",
          parameters: [
            {
              id: 1,
              value: "pws_id",
              label: "A public water system ID, starts with NM and has seven digits, the first two being the county FIPS code.",
              inputValue: "",
            },
            {
              id: 2,
              value: "tinwsys_id",
              label: "Public Water System internal database ID, if known.  Must be passed if NM number is not.",
              inputValue: "598",
            },
          ],
          responseType: "3",
        },
        {
          id: "14a",
          title: "Surface Water (Assessed Streams)",
          description:
            "Assessed Streams",
          category: "water",
          baseUrl: "https://prxy.env.nm.gov",
          endPoint: "/surface-water/assessed-streams",
          parameters: [
            {
              id: 1,
              value: "cycle",
              label: "Year of Assessment: 2018, 2020, or 2022",
              inputValue: "2018",
            },
          ],
          responseType: "5",
        },
        {
          id: "14b",
          title: "Surface Water (Impaired Streams)",
          description:
            "Impaired Streams",
          category: "water",
          baseUrl: "https://prxy.env.nm.gov",
          endPoint: "/surface-water/impaired-streams",
          parameters: [
            {
              id: 1,
              value: "cycle",
              label: "Year of Assessment: 2018, 2020, or 2022",
              inputValue: "2018",
            },
          ],
          responseType: "5",
        },
        {
          id: "14c",
          title: "Surface Water (Assessed Lakes)",
          description:
            "Assessed Lakes",
          category: "water",
          baseUrl: "https://prxy.env.nm.gov",
          endPoint: "/surface-water/assessed-lakes",
          parameters: [
            {
              id: 1,
              value: "cycle",
              label: "Year of Assessment: 2018, 2020, or 2022",
              inputValue: "2018",
            },
          ],
          responseType: "5",
        },
        {
          id: "14d",
          title: "Surface Water (Impaired Lakes)",
          description:
            "Impaired Lakes",
          category: "water",
          baseUrl: "https://prxy.env.nm.gov",
          endPoint: "/surface-water/impaired-lakes",
          parameters: [
            {
              id: 1,
              value: "cycle",
              label: "Year of Assessment: 2018, 2020, or 2022",
              inputValue: "2018",
            },
          ],
          responseType: "5",
        },
      ],
    };
  },
  getters: {
    apis(state) {
      return state.apis;
    },
    apiKey(state) {
      return state.apiKey;
    },
  },
  mutations: {
    setApiKey(state, payload) {
      state.apiKey = payload.apiKey;
    },
  },
  actions: {
    setupApiKey(context, payload) {
      context.commit("setApiKey", {
        apiKey: payload.apiKey,
      });
    },
    async getApiKeyFromServer(context) {
      const token = context.rootGetters.token;

      let url =
        "https://apaluma-apollo11-default-rtdb.firebaseio.com/auth.json?auth=" +
        token;

      const response = await fetch(url, {
        method: "GET",
      });

      // console.log({
      //   email: payload.email,
      //   password: payload.password,
      //   returnSecureToken: true
      // });

      const responseData = await response.json();

      if (!response.ok) {
        let errorMsg = null;
        errorMsg =
          responseData.message ||
          "Could not get the api key from the server. Please try again later.";

        const error = new Error(errorMsg);
        throw error;
      }

      // console.log(responseData);
      let apiKey = null;
      for (const key in responseData) {
        apiKey = responseData[key].API_Key;
      }

      const payload = { apiKey: apiKey };

      context.dispatch("setupApiKey", payload);
    },
  },
};
