import { createRouter, createWebHistory } from "vue-router";


import AccessApis from "./pages/AccessApis.vue";
import AnalyticsPage from "./pages/AnalyticsPage.vue";
import NotFound from './pages/NotFound.vue';
import UserAuth from './pages/auth/UserAuth.vue';
import store from './store/index.js';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: "/", redirect: "/sign-in" },
    { path: "/auth", redirect: "/sign-in" },
    {
      name: "main",
      path: "/main",
      meta: { requiresAuth: true },
      components: { default: AccessApis},
    }, 
    {
      name: "analytics",
      path: "/analytics",
      meta: { requiresAuth: true },
      components: { default: AnalyticsPage},
    },
    {
      name: 'sign-in',
      path: '/sign-in',
      component: () =>  import('./pages/auth/SignIn.vue'),
    },
    {
      name: 'sign-up',
      path: '/sign-up',
      component: () =>  import('./pages/auth/SignUp.vue'),
    },
    {
      name: 'forgot-password',
      path: '/forgot-password',
      component: () =>  import('./pages/auth/ForgotPassword.vue'),
    },
    {
      name: 'set-new-password',
      path: '/forgot-password/set-new-password',
      component: () =>  import('./pages/auth/SetNewPassword.vue'),
    },
    {
      name: 'reset-pin',
      path: '/forgot-password/reset-pin',
      component: () =>  import('./pages/auth/ResetPin.vue'),
    },
    // {
    //   path: "/users",
    //   components: {
    //     default: UsersList,
    //     footer: UsersFooter,
    //   },
    //   beforeEnter(to, from, next) {
    //     console.log("users beforeEnter");
    //     console.log(to, from);
    //     next();
    //   },
    // },
    { path: '/sign-in', component: UserAuth, meta: { requiresUnauth: true } },
    { path: "/:notFound(.*)", component: NotFound },
  ],
  linkActiveClass: "active",
  // scrollBehavior(_, _2, savedPosition) {
  //   // console.log(to, from, savedPosition);
  //   if (savedPosition) {
  //     return savedPosition;
  //   }
  //   return { left: 0, top: 0 };
  // },
});

router.beforeEach(function(to, _, next) {
  if (to.meta.requiresAuth && !store.getters.isAuthenticated) {
    next('/sign-in');
  // } else if (to.meta.requiresUnauth && store.getters.isAuthenticated) {
  //   next('/coaches');
  } else {
    next();
  }
});

// router.beforeEach(function (to, from, next) {
//   console.log("Global beforeEach");
//   console.log(to, from);
//   if (to.meta.needsAuth) {
//     console.log("Needs auth!");
//     next();
//   } else {
//     next();
//   }
//   if (to.name === "team-members") {
//     next();
//   } else {
//     next({ name: "team-members", params: { teamId: "t2" } });
//   }
//   next();
// });

// router.afterEach(function(to, from) {
//   // sending analytics data
//   console.log('Global afterEach');
//   console.log(to, from);
// });

export default router;
