<template>
  <div>
    <TheHeader title="Apaluma Apollo 11" />
    <div class="main-area">
      <base-card>
        <template #title>
          API Categories
        </template>
        <nav>
          <ul>
            <li>
              <base-button
                :mode="(selectedCategory === 'water')?'selected':'button'"
                @click="selectApis('water')"
              >
                Water
              </base-button>
            </li>
            <li>
              <base-button
                :mode="(selectedCategory === 'air')?'selected':'button'"
                @click="selectApis('air')"
              >
                Air
              </base-button>
            </li>
            <li>
              <base-button
                :mode="(selectedCategory === 'waste')?'selected':'button'"
                @click="selectApis('waste')"
              >
                Waste
              </base-button>
            </li>
            <li>
              <base-button
                :mode="(selectedCategory === 'service')?'selected':'button'"
                @click="selectApis('service')"
              >
                Service
              </base-button>
            </li>
          </ul>
        </nav>
      </base-card>
    </div>
    <api-input-output
      v-for="api in selectedAPIs"
      :key="api.id"
      :title="api.title"
      :description="api.description"
      :base-url="api.baseUrl"
      :end-point="api.endPoint"
      :parameters="api.parameters"
      :response-type="api.responseType"
    />
    <TheFooter title="Apaluma" />
  </div>
</template>

<script>
import ApiInputOutput from "../components/ApiInputOutput";
import TheHeader from "@/components/layout/TheHeader.vue";
import TheFooter from "@/components/layout/TheFooter.vue";

export default {
  components: {
    TheFooter,
    TheHeader,
    "api-input-output": ApiInputOutput,
  },
  props: ["avalableAPIs"],
  data() {
    return {
      selectedCategory: "air",
      fullAPIs: null,
      selectedAPIs: null,
    };
  },
  created() {
    this.fullAPIs = this.avalableAPIs;
    this.selectApis(this.selectedCategory);
  },
  methods: {
    selectApis(category) {
      this.selectedCategory = category;
      let selected = [];
      this.fullAPIs.forEach((api) => {
        if (api.category === category) {
          selected.push(api);
        }
      });
      // console.log("TNU ======= " + selected + " === " + category);
      this.selectedAPIs = selected;
    },
  },
};
</script>

<style scoped>
h1 {
  font-size: 30pt;
  color: rgb(54, 0, 61);
  margin: 0;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  font-size: 20pt;
  justify-content: center;
  align-items: center;
}

li {
  margin: 0 0.5rem;
}

button {
  font: inherit;
  border: 1px solid #070024;
  background-color: #070024;
  color: white;
  cursor: pointer;
  padding: 0.75rem 2rem;
  border-radius: 30px;
}

button:hover,
button:active {
  border-color: #002350;
  background-color: #002350;
}

.main-area {
  grid-row: content;
  /* Optional */
  background-color: #ffffff;
  color: #333;
}
</style>
