<template>
  <section>
    <base-card>
      <p>
        Coming soon...
      </p>
    </base-card>
  </section>
</template>

<script>
</script>
