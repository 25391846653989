<template>
  <footer>
    <p>Apaluma © 2024</p>
  </footer>
</template>

<script>
export default {
  props: ['title'],
  computed: {
    isLoggedIn() {
      return this.$store.getters.isAuthenticated;
    }
  },
  methods: {
    logout() {
      this.$store.dispatch('logout');
      this.$router.replace('/auth');
    }
  }
}
</script>

<style scoped>
footer {
  width: 100%;
  /*height: 5rem;*/
  grid-row: footer;
  padding: 1rem;
  background-color: #4e0148;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  flex: 0;
}

footer a {
  text-decoration: none;
  color: #ffffff;
  display: inline-block;
  padding: 0.75rem 1.5rem;
  border: 1px solid transparent;
}

a:active,
a:hover,
a.router-link-active {
  border: 1px solid #f391e3;
}

h1 {
  font-size: 40pt;
  color: white;
  margin: 0;
}

p {
  font-size: 20pt;
  color: white;
  margin: 0;
}


h1 a {
  color: white;
  margin: 0;
}

h1 a:hover,
h1 a:active,
h1 a.router-link-active {
  border-color: transparent;
}

footer nav {
  width: 90%;
  margin: auto;
  display: flex;
  font-size: 20pt;
  justify-content: space-between;
  align-items: center;
}

footer ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  font-size: 20pt;
  justify-content: center;
  align-items: center;
}

li {
  margin: 0 0.5rem;
}

</style>
