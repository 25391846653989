<template>
  <div>
    <router-view :avalable-a-p-is="storeAPIs" />
  </div>
</template>

<script>
export default {
  computed: {
    storeAPIs() {
      return this.$store.getters["apis/apis"];
    },
  },
};
</script>

<style>
* {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
}

body {
  min-height: 100vh;
  display: grid;
  grid-template-rows: [header] auto [main] 1fr [footer] auto;

  /* Optional */
  margin: 0;
  line-height: 1.6;
}

main {
  grid-row: content;

  /* Optional */
  padding: 1rem;
  background-color: #ffffff;
  color: #333;
}
</style>